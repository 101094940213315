import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-824db86e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "top-background"
};
import i18n from '@/language/index';
export default {
  __name: 'TopContent',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", {
        class: _normalizeClass(["content", _unref(i18n).global.locale.value === 'en' ? 'content-en' : ''])
      }, _toDisplayString(_ctx.$t('aiHeuristicCard.home.topTitle')), 3)]);
    };
  }
};