import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1e7fe3a2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home-page"
};
const _hoisted_2 = {
  class: "game-introduction"
};
const _hoisted_3 = {
  class: "content"
};
import TopContent from './content/TopContent.vue';
import CenterContent from './content/CenterContent.vue';
import FooterContent from './content/FooterContent.vue';
// import Header from '@/components/Header'
// import PageFooter from '@/components/PageFooter'
import i18n from '@/language/index';
export default {
  __name: 'Index',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(TopContent), _createVNode(CenterContent), _createVNode(FooterContent), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("p", {
        class: _normalizeClass(["common-text", _unref(i18n).global.locale.value === 'en' ? 'text-two-en' : ''])
      }, _toDisplayString(_ctx.$t('aiHeuristicCard.home.introduction')), 3), _createElementVNode("p", {
        class: _normalizeClass(["common-text", _unref(i18n).global.locale.value === 'en' ? 'text-one-en' : ''])
      }, _toDisplayString(_ctx.$t('aiHeuristicCard.home.developing')), 3)])])]);
    };
  }
};